import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor( private http:HttpClient) { }

  readonly BaseURI = 'https://localhost:5005/home';

  ContactMe(formData) {
    return this.http.post(this.BaseURI + '/SendContactMail', formData);
  }


 
}
