<!--Begin Section Services-->
<section id="gallery">
    <div class="container-allPage">
        <div class="grid-container">
            <div class="span-wrapper">
                <span class="title-shadow">Gallery</span>
            </div>
            <div class="headerSection">
                <h2>Gallery</h2>
            </div>
            <div class="jumbotron bg-white text-center">
                <div class="  text-center">
                    <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" id="pills-home-tab" data-toggle="pill" href="#pills-home"
                                role="tab" aria-controls="pills-home" aria-selected="false">Mukta</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link active" id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
                                role="tab" aria-controls="pills-profile" aria-selected="true">Art</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact"
                                role="tab" aria-controls="pills-contact" aria-selected="false">Travel</a>
                        </li>
                    </ul>
                </div>
                <div class=" bg-light tab-content" id="pills-tabContent" style=" padding: 10px;">
                    <div class="tab-pane fade" id="pills-home" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                        <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
                    </div>
                    <div class="tab-pane fade  show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <ngx-gallery [options]="galleryOptions" [images]="galleryImagesAnimal">
                        </ngx-gallery>

                    </div>
                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <ngx-gallery [options]="galleryOptions" [images]="galleryImagesNature">
                        </ngx-gallery>

                    </div>
                </div>

                <hr>
                <h3 style="text-align: center;"> My Videos </h3>
                <br>
                <div class="text-center">
                    <div class="row">




                    <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <div class="bg-video card text-black  mb-3">
                            <div class="card-body">
                                <h6 class="video-caption">Sewing Masks for Donation to Local Hospitals for
                                    COVID-19 Pandemic in San
                                    Jose. </h6>
                                <div class="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/NA6mU95REFU"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <div class="bg-video card text-black  mb-3">
                            <div class="card-body">
                                <h6 class="video-caption">Solo dance performance on stage. Dance form - Hindustani
                                    Classical
                                    Kathak Dance. </h6>
                                <div class="video-container">
                                    <iframe src="https://www.youtube.com/embed/tEjQqNjOe1A" frameborder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen>

                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <div class="bg-video card text-black  mb-3">
                            <div class="card-body">
                                <h6 class="video-caption">Solo singing on stage. Song format- Hindustani Classical
                                    Raaga.
                                    Kathak Dance. </h6>
                                <div class="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/HrBGFlMHUwQ"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <div class="bg-video card text-black  mb-3">
                            <div class="card-body">
                                <h6 class="video-caption">Solo singing performance. Song: Jeena jeena- Hindi Bollywood
                                    Music. </h6>
                                <div class="video-container">
                                    <iframe
                                        src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fpankaj.jaiswal%2Fvideos%2F10154281157338626%2F&show_text=0&width=264"
                                        width="264" height="476" style="border:none;overflow:hidden" scrolling="no"
                                        frameborder="0" allowfullscreen="true"
                                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                        allowFullScreen="true"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <div class="bg-video card text-black  mb-3">
                            <div class="card-body">
                                <h6 class="video-caption">A video of me para sailing in Thailand
                                </h6>
                                <div class="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/T7eEBpMcsWA"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <div class="bg-video card text-black  mb-3">
                            <div class="card-body">
                                <h6 class="video-caption">Group dance performance on stage. I am on the extreme left.
                                </h6>
                                <div class="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/G_mNSOQXDrw"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>

                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <div class="bg-video card text-black  mb-3">
                            <div class="card-body">
                                <h6 class="video-caption">Duet dance performance on stage. I am on the left. Dance form
                                    - Hindustani Classical
                                    Kathak Dance. </h6>
                                <div class="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/9fODgY3a9Hs"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <div class="bg-video card text-black  mb-3">
                            <div class="card-body">
                                <h6 class="video-caption">Vibgyor Viva Group Dance. Song: Saxobeat </h6>
                                <div class="video-container">
                                    <iframe src="https://www.youtube.com/embed/vp7kfk6Tog0" frameborder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen>

                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <div class="bg-video card text-black  mb-3">
                            <div class="card-body">
                                <h6 class="video-caption">Duet dance performance. Dance form - Hindustani Classical
                                    Kathak Dance. </h6>
                                <div class="video-container">
                                    <iframe src="https://www.youtube.com/embed/pQo7DNzxZ_Y" frameborder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen>

                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <div class="bg-video card text-black  mb-3">
                            <div class="card-body">
                                <h6 class="video-caption">Group dance performance. Dance form - Indian Patriotic dance
                                    performance. </h6>
                                <div class="video-container">
                                    <iframe src="https://www.youtube.com/embed/fwiCSK4pbL4" frameborder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen>

                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <div class="bg-video card text-black  mb-3">
                            <div class="card-body">
                                <h6 class="video-caption">Republic day speech in School in the year 2012 </h6>
                                <div class="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/mW0VPqTHQ0A"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <div class="bg-video card text-black  mb-3">
                            <div class="card-body">
                                <h6 class="video-caption">Group dance performance. I am in the center. Dance form -
                                    Indian
                                    Patriotic Dance. </h6>
                                <div class="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/PFvZYTZis3c"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <div class="bg-video card text-black  mb-3">
                            <div class="card-body">
                                <h6 class="video-caption">Duet dance performance. I am on the right. Dance form - Indian
                                    Kathak Dance. </h6>
                                <div class="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/00ib4x3UWdQ"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe> </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <div class="bg-video card text-black  mb-3">
                            <div class="card-body">
                                <h6 class="video-caption">Group dance performance. I am on the left. Dance form - Indian
                                    Garba Dance performed on Navratri. </h6>
                                <div class="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/TqTDX-3H-mY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 col-sm-12 ">
                        <div class="bg-video card text-black  mb-3">
                            <div class="card-body">
                                <h6 class="video-caption">Group dance performance. I am on the left(orange colour). Dance form - Indian
                                   Patriotic Dance. </h6>
                                <div class="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/0097rV_dueo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </div>                           </div>
                        </div>
                    </div>




                </div>
                





                </div>
            </div>
        </div>
    </div>
    
</section>