<section id="contact">
    <div class="container-allPage">
        <div id="backgroundMapImage"></div>
        <div class="grid-container">
            <div class="span-wrapper">
                <span class="title-shadow">Contact</span>
            </div>
            <div class="headerSection">
                <h2>Contact</h2>
            </div>
            <div>
                

    
                <img  src="assets/images/contact.jpg"  alt="Contact Mukta" class="responsive">
                <img class="img-fluid " src="assets/images/about/title.png" alt="">
            </div>

            <div class=" " >
                <div class="jumbotron " style=" background-color:#e6d4d1;">
                    <!-- <h3>Contact Me</h3> -->
                    <div class="">
                        <div class="">
                            <h3>Contact Me</h3>
                        </div>
                        <div class="">
                            <div class="row">
                                <div class=" col-md-8">
                                    <!-- <iframe class="gmap"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3169.9949229151543!2d-121.8610888494919!3d37.38995234188075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fcc34fc48fa61%3A0x901e2f2645b5f889!2sFlagstone%20Dr%2C%20San%20Jose%2C%20CA%2095132!5e0!3m2!1sen!2sus!4v1586401088357!5m2!1sen!2sus"
                                        frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                                        tabindex="0"></iframe> -->

                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d49920.00140441801!2d-121.76996336307005!3d38.556811783692226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808529999495543f%3A0xc3013f1b6ee28fff!2sDavis%2C%20CA!5e0!3m2!1sen!2sus!4v1635243348238!5m2!1sen!2sus"  frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                                        tabindex="0"></iframe>
                                </div>
                                <div class="col-md-8">

                                    <span><i class="fa fa-map-marker text-danger fa-2x"></i>  Davis, California, USA</span><br><br>

                                    <span><i class="fa fa-envelope text-primary fa-2x"></i> <a
                                            href="mailto:mukta@jaiswal.in"> mukta@jaiswal.in</a></span><br><br>

                                    <span><i class="fa fa-phone text-success fa-2x"></i> +1 (408) *****90 </span>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            <!-- <div class="col-md-1">Hello</div> -->
            <!-- <div class="col-right">
                <div class="jumbotron " style=" background-color:#e6d4d1;">
                <h3>Connect with Me</h3>
                <span>
                    <form name="form" (ngSubmit)="formContact.form.valid && onSubmit(formContact)" #formContact="ngForm"
                        novalidate>
                        
                        <div class="form-row ">
                            <div class="form-group  col-md-6">
                                
                                <label for="txtFullName"> Full Name: </label><span class="required">*</span>
                                <input type="text" class="form-control " id="txtFullName" placeholder="Full Name"
                                    name="fullname" [(ngModel)]="model.fullname" #fullname="ngModel"
                                    [ngClass]="{ 'is-invalid': formContact.submitted && fullname.invalid }" required>
                                <div *ngIf="formContact.submitted && fullname.invalid" class="invalid-feedback">
                                    <div *ngIf="fullname.errors.required">Full Name is required</div>
                                </div>
                                
                            </div> -->

                            <!-- <div class="form-group  col-md-6">
                                <label for="txtCity"> City:</label><span class="required">*</span>
                                <input type="text" class="form-control " id="txtCity" placeholder="City"
                                name="city" [(ngModel)]="model.city" #city="ngModel" [ngClass]="{ 'is-invalid': formContact.submitted && city.invalid }" required>
                                <div *ngIf="formContact.submitted && fullname.invalid" class="invalid-feedback">
                                    <div *ngIf="city.errors.required">City is required</div>
                                </div>
                            </div> -->
                            
                            <!-- <div class="form-group  col-md-6">
                                <label for="e-mail"> E-mail:</label><span class="required">*</span>
                                <input type="email" class="form-control " id="email" placeholder="E-mail" name="email"
                                    [(ngModel)]="model.email" #email="ngModel"
                                    [ngClass]="{ 'is-invalid': formContact.submitted && email.invalid }" required>
                                <div *ngIf="formContact.submitted && email.invalid" class="invalid-feedback">
                                    <div *ngIf="email.errors.required">E-mail is required</div>
                                </div>
                            </div> -->
                            <!-- <div class="form-group col-md-6">
                                <label for="phone"> Phone no.:</label>
                                <input type="integer" class="form-control " id="phone" placeholder="Phone no." name="phone"  [(ngModel)]="model.phone" #phone="ngModel" >
                                <div *ngIf="formContact.submitted && phone.invalid" class="invalid-feedback">
                                    <div *ngIf="phone.errors.required">Phone no. is required</div>
                                </div>
                            </div> -->
                            <!-- <div class="form-group col-md-12 ">
                                <label class="" for="message"> Message:</label><span class="required">*</span>
                                <textarea id="message" rows="5" class="form-control" name="message"
                                    [(ngModel)]="model.message" #message="ngModel"
                                    [ngClass]="{ 'is-invalid': formContact.submitted && message.invalid }"
                                    required></textarea>
                                <div *ngIf="formContact.submitted && message.invalid" class="invalid-feedback">
                                    <div *ngIf="message.errors.required">Message is required</div>
                                </div>
                            </div>

                        </div> -->
                        <!-- <div #recaptcha ></div> -->
                        <!-- <div style="width: 100px;">
                            <div class="g-recaptcha" style="transform:scale(0.80);-webkit-transform:scale(0.80);transform-origin:0 0;-webkit-transform-origin:0 0;">
                            <re-captcha (resolved)="resolved($event)"
                                siteKey="6LePbq4UAAAAAPqwJU8u5g1Of1TIEMyoPpJQpyaD" style="width: 25px;"></re-captcha>
                            </div>
                                
                        </div>
                        <div *ngIf="captchaData==='N'" class="">
                            <div class="text-danger">{{errorMessage}}</div>
                        </div>

                        <br>
                        <div class=" form-group row " style="align-content: center">
                            <div class="container">
                                <div class="centerButton">
                                    <div class="button-row">
                                        <div>
                                            <button title="SUBMIT" ></button>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </form>
                </span>
            </div>
        </div> -->
            
        </div>
    </div>
</section>
<!--End Section Contact-->

