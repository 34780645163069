
import { FormsModule, NgForm,FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit, ElementRef, ViewChild ,VERSION} from '@angular/core';
import Swal from 'sweetalert2';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']

})
export class ContactComponent implements OnInit {
  model = {
    fullname: '',
    city: '',
    email:'',
    phone:'',
    message:''
  };

  captchaData: string;
  errorMessage: string;

  // resolved(captchaResponse: string) {
  //   this.captchaData = captchaResponse;
    
  // }  
  
  constructor(private userService: UserService) {  }

  onSubmit(form:NgForm) { 


    



    this.userService.ContactMe(form.value).subscribe();


    if(this.captchaData != 'N') {
      Swal.fire({
        //position: 'top-end',
        icon: 'success',
        title: "Good job!",
    text: "The form was submitted successfully!",
        showConfirmButton: true,
       // timer: 1500
      })
      form.resetForm();
      grecaptcha.reset();
      this.captchaData='N';
      this.errorMessage=" ";
    }else{
      this.errorMessage = "Please select captcha";
    }
    


    
    
  } 
 
  ngOnInit() {
    this.captchaData = 'N';
    this.errorMessage = '';
   }
 
  
  
}




