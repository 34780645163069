<div class="fixed-top">
  <div>
    <nav class="navbar navbar-expand styles" style="background-color: #58c0fc;">
      <div class="">
        <p><i class="fa fa-envelope  mx-2 text-white"></i><a href="mailto:mukta@jaiswal.in" class="text-white" > mukta@jaiswal.in</a></p>
      </div>
      <div class=" flex-row ml-auto  d-sm-flex d-sm-flex d-xs-flex">
        <a href="https://www.facebook.com/mukta.jaiswal.13" target="_blank"><i class="fa fa-facebook fa-lg text-white mx-lg-3 mx-1 pt-0"></i></a>
        <a href="https://www.linkedin.com/in/mukta-jaiswal-99270a221" target="_blank"><i class="fa fa-linkedin fa-lg text-white mx-lg-3 mx-1 pt-0"></i></a>
        <a href="https://www.youtube.com/channel/UC2bWWzZ4pRbKZB_HjTKhKSQ" target="_blank"><i class="fa fa-youtube fa-lg text-white mx-lg-3 mx-1"></i></a>
      </div>
    </nav>
  </div>


  <nav class="navbar navbar-expand-lg navbar-light " style="background-color: #e3f2fd;">


    <a class="navbar-brand" routerLink="/">   
      <img class="img-fluid LogoImg" src="assets/images/about/title.png" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler"
      aria-controls="navbarTogglerDemo" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarToggler">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item ">
          <a class="nav-link pt-1 " routerLinkActive="active-link" routerLink="/home"><i class="fa fa-home fa-2x "></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active-link" routerLink="/resume">RESUME</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active-link" routerLink="/gallery">GALLERY</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active-link" routerLink="/achievements">ACHIEVEMENTS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active-link" routerLink="/articles">BLOGS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active-link" routerLink="/contact">CONTACT</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" routerLinkActive="active-link" routerLink="/testpage">Test</a>
        </li> -->
      </ul>
    </div>
  </nav>

</div>
<div class="pb-6" style="padding-bottom: 5rem;">

</div>