<section id="home" class="hero-bg-one" style="background: url('assets/images/about/bg2.jpg')no-repeat center center / cover">
    <div class="hero-wrap">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="text-wrapp">
                        <div class="rotated-text">
                            <h6>Hello!</h6>
                            <h1>I Am Mukta Jaiswal</h1>
                            

                            
                        </div>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="top-img top-img-five">
                        <img alt="image description" src="assets/images/about/bg.png" class="img-responsive">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-shape">
        <img src="https://cssmaterial.com/sophia/demo/img/shap.png" alt="shape image">
    </div>
</section>

<div class="">
    <div class="row" style="margin-top: -10px;">
        
    </div>

</div>

<!-- ------------------------------------------------------------------------------------------- -->


<!--Begin Section About-->
<section id="about">
    <div class="container-allPage">
        <div class="grid-container">
            <div class="span-wrapper">
                <span class="title-shadow">About</span>
            </div>
            <div class="headerSection" ><h2>About</h2></div>
            <div class="  col-image">
                <div class="image-container2">
                    <div class="image-container">
                        <img src="assets/images/mukta.jpg" alt="Mukta Jaiswal Profile Picture">
                    </div>
                    
                </div>
            </div>
            <div class="column-2">
                <div>
                    <h1 style="font-family:cursive; color: #ab5256;">Mukta Jaiswal</h1>
                    <h4><span class="sub-title">1st year UG student for Computer Engineering</span></h4>
                    <br>
                    <p style="font-weight: 500;">  I am Mukta Jaiswal, and I am currently completing my undergraduate degree in Computer Engineering through a transfer program from De Anza Collge. I will complete my graduation in 2023.</p>
                    <br>
                    <ul>
                        <li class="two_column_list"><div><strong>Bith year: </strong></div> October 2001</li>
                        <li class="two_column_list"><div><strong>Nationality: </strong></div> Indian</li>
                        <li class="two_column_list"><div><strong>Current city: </strong></div> San Jose, California</li>
                        <li class="two_column_list"><div><strong>Education: </strong></div> Passed High School with Merit</li>
                        <li class="one_column_list"><div><strong>Qualification: </strong></div>  I will complete my undergraduate degree in Computer Engineering in 2023</li>
                        <li class="one_column_list"><div><strong>Background: </strong></div> 1st year undergraduate student in Computer Engineering branch</li>
                    </ul>

                    
                </div>
            </div>
        </div>

        


    </div>
</section>
<!--End Section About-->

<div class="container">
<div class="row">

    <ul class="timeline" id="timeline">
        <li class="li complete">
            <div class="timestamp">
                <span class="author">Born in Mumbai, India</span>

            </div>
            <div class="status">
                <h4> 2001 </h4>
            </div>
        </li>
        <li class="li complete">
            <div class="timestamp">
                <span class="author">Passed 10th with Merit</span>

            </div>
            <div class="status">
                <h4> 2017 </h4>
            </div>
        </li>
        <li class="li complete">
            <div class="timestamp">
                <span class="author">Passed 12th with Merit</span>

            </div>
            <div class="status">
                <h4> 2019 </h4>
            </div>
        </li>

        <li class="li">
            <div class="timestamp">
                <span class="author">Transfer to a university</span>

            </div>
            <div class="status">
                <h4> 2021 </h4>
            </div>
        </li>
    </ul>
</div>
</div>

