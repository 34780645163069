<div class="carousel-header">
    <div class="row row m-0">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">

            <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                     <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img class="w-100 img-fluid-slider img-fluid" src="assets/images/mukta2.jpg"
                            alt="First slide" >
                        <div class="carousel-caption d-none d-lg-block">
                            <h1>Welcome to my Website!</h1>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img class="w-100 img-fluid-slider img-fluid" src="assets/images/mukta1.jpg"
                            alt="Second slide" >
                        <div class="carousel-caption d-none d-lg-block">
                            <h1>Welcome to my Website!</h1>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img class="w-100 img-fluid-slider img-fluid" src="assets/images/mukta3.jpg"
                            alt="Third slide" >
                        <div class="carousel-caption d-none d-lg-block">
                            <h1>Welcome to my Website!</h1>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>

</div>


<!-- ------------------------------------------------------------------------------------------- -->


<!--Begin Section About-->
<section id="about">
    <div class="container-allPage">
        <div class="grid-container">
            <div class="span-wrapper">
                <span class="title-shadow">About</span>
            </div>
            <div class="headerSection" ><h2>About</h2></div>
            <div class="  col-image">
                <div class="image-container2">
                    <div class="image-container">
                        <div class="image-container-bottom1">
                            <div class="image-container-bottom2">
                                <img  src="assets/images/mukta.jpg"  alt="Mukta Jaiswal Profile Picture">
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="column-2">
                <div>
                    <h1 style="font-family:cursive; color: #ab5256;">Mukta Jaiswal</h1>
                    <h4><span class="sub-title">3<sup>rd</sup> year UG student for Computer Science and Engineering</span></h4>
                    <br>
                    <p style="font-weight: 500;">  I am Mukta Jaiswal, a computer enthusiast with a profound knowledge of several programming languages. Eager to advance professionally by utilizing my communication and problem-solving skills. I will be receiving my bachelor’s degree in Computer Science and Engineering in 2023.</p>
                    <br>
                    <ul>
                        <li class="two_column_list"><div><strong>Bith year: </strong></div> October 2001</li>
                        <li class="two_column_list"><div><strong>Nationality: </strong></div> Indian</li>
                        <li class="two_column_list"><div><strong>Current city: </strong></div> Davis, California</li>
                        <li class="two_column_list"><div><strong>Education: </strong></div> Junior at UC Davis.</li>
                        <li class="one_column_list"><div><strong>Skills: </strong></div> Leadership, Debugging, Java,  Python, HTML, CSS, SQL, Data  Structure and Algorithm, Management.</li>
                    </ul>

                    
                </div>
            </div>
        </div>

        


    </div>
</section>
<!--End Section About-->

<div class="container " style="display: flex; justify-content: center;align-items: center;">
<div class="row">

    <ul class="timeline" id="timeline">
        <li class="li complete">
            <div class="timestamp">
                <span class="author">Born in Mumbai, India</span>

            </div>
            <div class="status">
                <h4> 2001 </h4>
            </div>
        </li>
        <li class="li complete">
            <div class="timestamp">
                <span class="author">Passed 10th with Merit</span>

            </div>
            <div class="status">
                <h4> 2017 </h4>
            </div>
        </li>
        <li class="li complete">
            <div class="timestamp">
                <span class="author">Passed 12th with Merit</span>

            </div>
            <div class="status">
                <h4> 2019 </h4>
            </div>
        </li>

        <li class="li complete">
            <div class="timestamp">
                <span class="author">Transferred to UC Davis</span>

            </div>
            <div class="status">
                <h4> 2021 </h4>
            </div>
        </li>
        <li class="li">
            <div class="timestamp">
                <span class="author">Graduation</span>

            </div>
            <div class="status">
                <h4> 2023 </h4>
            </div>
        </li>
    </ul>
</div>
</div>

