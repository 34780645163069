<!--Begin Section Services-->
<section id="resume">
    <div class="container-allPage">
        <div class="grid-container">
            <div class="span-wrapper">
                <span class="title-shadow">Resume</span>
            </div>
            <div class="headerSection"><h2>Resume</h2></div>
            <div class="  embed-responsive ">
                <div class="card">
                    <div class="">
                        <div class="row float-right">
                            
                            <div class="col-sm-6 col-md-1 col-xs-1">
                                <div class="centerButton btn-big">
                                    <div class="button-row">
                                        <div>
                                            <button (click)="openLink()" title="Download Resume"></button>
                                        </div>
                                    </div>
                                </div>
                                <button (click)="openLink()" class="btn-small"> <i class="fa fa-download" ></i>  </button>
                            </div>
                        </div>            
                    </div>
                    <div class=" ">
                        
                        <pdf-viewer [src]="pdfSrc" 
                            [render-text]="true"
                            [original-size]="false"
                            [autoresize]="true">
                            
                        </pdf-viewer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--End Section Services-->








