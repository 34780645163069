import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ResumeComponent } from './resume/resume.component';
import { ContactComponent } from './contact/contact.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AchievementsComponent } from './achievements/achievements.component';
import { ArticlesComponent } from './articles/articles.component';
import { TestpageComponent } from './testpage/testpage.component';


const routes: Routes = [
   { path: 'home',  component: HomeComponent },
   { path: 'resume', component: ResumeComponent },
   { path: 'gallery',  component: GalleryComponent },
   { path: 'achievements',  component: AchievementsComponent },
   { path: 'articles',  component: ArticlesComponent },
   { path: 'contact', component: ContactComponent},
   { path: 'testpage', component: TestpageComponent},

   { path: '', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
