
<!--Begin Section Services-->
<section id="services">
    <div class="container-allPage MyAchievements">
        <div class="grid-container">
            
                <div class="span-wrapper big-head">
                    <span class="title-shadow">Achievements</span>
                </div>
                <div class="headerSection big-head"><h2>Achievements</h2></div>
           
            
                <div class="span-wrapper small-head">
                    <span class="title-shadow">Awards</span>
                </div>
                <div class="headerSection small-head"><h2>Awards</h2></div>
            
            <div class="col">
                <i class="icofont-certificate"></i>	
                <h3>Awarded the De Anza Commission Scholarship award for the year 2020-2021 for 
                    portraying strong commitment to community interest and volunteer service on campus.</h3>
                <div class="bar_services"></div>
                <p><img src="assets/achievements/Picture3.png" alt="Mukta Jaiswal" class="rounded img-thumbnail" style="width:325px;height:325px;"></p>
            </div>
            <div class="col">
                <i class="icofont-certificate"></i>	
                <h3>Awarded the De Anza Business/Computer Science award for portraying academic excellence towards my major with a GPA of 4.0 at De Anza College in 2021.</h3>
                <div class="bar_services"></div>
                <p><img src="assets/achievements/Picture2.png" alt="Mukta Jaiswal" class="rounded img-thumbnail" style="width:325px;height:325px;"></p>
            </div>
            <div class="col">
                <i class="icofont-certificate"></i>	
                <h3>Awarded for Academic performance at De Anza College and was honored at Student leadership gala event in 2021.</h3>
                <div class="bar_services"></div>
                <p><img src="assets/achievements/Picture1.png" alt="Mukta Jaiswal" class="rounded img-thumbnail" style="width:325px;height:325px;"></p>
            </div>
            <div class="col cell-1">
                <i class="icofont-certificate"></i>	
                <h3>Became the Vice President of Academics at De Anza Honors Program in 2020.</h3>
                <div class="bar_services"></div>
                <p><img src="assets/achievements/award1.jpg" alt="Mukta Jaiswal" class="rounded img-thumbnail" style="width:325px;height:325px;"></p>
            </div>
            <div class="col cell-2">
                <i class="icofont-trophy"></i>	
                <h3>Winner of donut decoration competition in the year 2019.</h3>
                <div class="bar_services"></div>
                <p><img src="assets/achievements/award2.jpg" alt="Mukta Jaiswal" class="rounded img-thumbnail" style="width:325px;height:325px;"></p>
            </div>
            <div class="col cell-3">
                <i class="icofont-certificate"></i>
                <h3> Appreciation certificate by Rotary international Club in the year 2018.</h3>
                <div class="bar_services"></div>
                <p> <img src="assets/achievements/award3.jpg" alt="Mukta Jaiswal" class="rounded img-thumbnail" style="width:325px;height:325px;"></p>
            </div>
            <div class="col cell-4">
                <i class="icofont-trophy"></i>
                <h3> Winner of Dance Competition in the year 2017</h3>
                <div class="bar_services"></div>
                <p><img src="assets/achievements/award4.jpg" alt="Mukta Jaiswal" class="rounded img-thumbnail" style="width:325px;height:325px;"></p>
            </div>
            <div class="col cell-5">
                <i class="icofont-certificate"></i>
                <h3> Winner of treasure hunt competition in the year 2016.</h3>
                <div class="bar_services"></div>
                <p> <img src="assets/achievements/award5.jpg" alt="Mukta Jaiswal" class="rounded img-thumbnail" style="width:325px;height:325px;"></p>
            </div>
            <div class="col cell-6">
                <i class="icofont-medal"></i>
                <h3> All my gymnastics and running race medals and trophies.</h3>
                <div class="bar_services"></div>
                <p> <img src="assets/achievements/award6.jpg" alt="Mukta Jaiswal" class="rounded img-thumbnail" style="width:325px;height:325px;"></p>
            </div>
            <div class="col cell-7">
                <i class="icofont-certificate"></i>
                <h3> First postion in singing competition in the year 2015.</h3>
                <div class="bar_services"></div>
                <p> <img src="assets/achievements/award7.jpg" alt="Mukta Jaiswal" class="rounded img-thumbnail" style="width:325px;height:325px;"></p>
            </div>
            <div class="col cell-8">
                <i class="icofont-trophy"></i>
                <h3> First runner-up in treasure hunt competition in the year 2014.</h3>
                <div class="bar_services"></div>
                <p> <img src="assets/achievements/award8.jpg" alt="Mukta Jaiswal" class="rounded img-thumbnail" style="width:325px;height:325px;"></p>
            </div>
            <div class="col cell-9">
                <i class="icofont-medal"></i>
                <h3>  Gold medal in Taekwondo competition in the year 2013.</h3>
                <div class="bar_services"></div>
                <p> <img src="assets/achievements/award9.jpg" alt="Mukta Jaiswal" class="rounded img-thumbnail" style="width:325px;height:325px;"></p>
            </div>
            <div class="col cell-10">
                <i class="icofont-trophy"></i>
                <h3> Winner of Clay Modelling competition in the year 2012.</h3>
                <div class="bar_services"></div>
                <p> <img src="assets/achievements/award10.jpg" alt="Mukta Jaiswal" class="rounded img-thumbnail" style="width:325px;height:325px;"></p>
            </div>
            <div class="col cell-11">
                <i class="icofont-trophy"></i>
                <h3> My first winner award in a quiz contest in the year 2011.</h3>
                <div class="bar_services"></div>
                <p> <img src="assets/achievements/award11.jpg" alt="Mukta Jaiswal" class="rounded img-thumbnail" style="width:325px;height:325px;"></p>
            </div>
            <div class="col cell-12">
                <i class="icofont-certificate"></i>
                <h3> First runner-up in a dance competition in the year 2010.</h3>
                <div class="bar_services"></div>
                <p> <img src="assets/achievements/award12.jpg" alt="Mukta Jaiswal" class="rounded img-thumbnail" style="width:325px;height:325px;"></p>
            </div>


        </div>
    </div>
</section>
<!--End Section Services-->