import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation,NgxGalleryImageSize } from 'ngx-gallery-9';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  galleryImagesAnimal: NgxGalleryImage[];
  galleryImagesNature:NgxGalleryImage[];

  constructor() { }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        "imageDescription": true,
        "previewDescription":true

      },
      {
        width: '100%',
        height: '700px',
        
        imageSize: NgxGalleryImageSize.Cover,
        
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Zoom
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '300px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        preview: true,
        imageDescription: true
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: true,
        imageDescription: true
      }
    ];

    this.galleryImages = [
      {
        small: 'assets/images/gallery/2.jpg',
        medium: 'assets/images/gallery/2.jpg',
        big: 'assets/images/gallery/2.jpg',
        description:'With my Mom'

        
        
      },
      
      {
        small: 'assets/images/gallery/1.jpg',
        medium: 'assets/images/gallery/1.jpg',
        big: 'assets/images/gallery/1.jpg',
        description:'Participating in Model United Nations conference'

      },
      
      {
        small: 'assets/images/gallery/3.jpg',
        medium: 'assets/images/gallery/3.jpg',
        big: 'assets/images/gallery/3.jpg',
        description:'With my Model United Nations Committee Team'

      },
      {
        small: 'assets/images/gallery/4.jpg',
        medium: 'assets/images/gallery/4.jpg',
        big: 'assets/images/gallery/4.jpg',
        description:'Watching a cricket match in the stadium with my brother'

      },
      {
        small: 'assets/images/gallery/5.jpg',
        medium: 'assets/images/gallery/5.jpg',
        big: 'assets/images/gallery/5.jpg',
        description:'Doing River Crossing'

      },
      {
        small: 'assets/images/gallery/6.jpg',
        medium: 'assets/images/gallery/6.jpg',
        big: 'assets/images/gallery/6.jpg',
        description:'Myself'

      },
      {
        small: 'assets/images/gallery/7.jpg',
        medium: 'assets/images/gallery/7.jpg',
        big: 'assets/images/gallery/7.jpg',
        description:'Gymnastics - Human Pyramid, in the 2nd row from top, to the left'

      },
      {
        small: 'assets/images/gallery/8.jpg',
        medium: 'assets/images/gallery/8.jpg',
        big: 'assets/images/gallery/8.jpg',
        description:'Swimming in the pool'

      },
      {
        small: 'assets/images/gallery/9.jpg',
        medium: 'assets/images/gallery/9.jpg',
        big: 'assets/images/gallery/9.jpg',
        description:'Myself'

      }
      
    ];

    this.galleryImagesAnimal = [
      {
        small: 'assets/images/animals/1.jpg',
        medium: 'assets/images/animals/1.jpg',
        big: 'assets/images/animals/1.jpg',
        description:' 5 canvas painting including clay flowers and tissue paper work.'
      },
      {
        small: 'assets/images/animals/2.jpg',
        medium: 'assets/images/animals/2.jpg',
        big: 'assets/images/animals/2.jpg',
        description:'Made and painted these heartfelt flowers from paper.'

      },
      {
        small: 'assets/images/animals/3.jpg',
        medium: 'assets/images/animals/3.jpg',
        big: 'assets/images/animals/3.jpg',
        description:'Made this Name Plate using heartfelt flowers.'
      },
      {
        small: 'assets/images/animals/4.jpg',
        medium: 'assets/images/animals/4.jpg',
        big: 'assets/images/animals/4.jpg',
        description:'One stroke painting by me.'
      },
      {
        small: 'assets/images/animals/5.jpg',
        medium: 'assets/images/animals/5.jpg',
        big: 'assets/images/animals/5.jpg',
        description:'One stroke painting by me.'
      },
      {
        small: 'assets/images/animals/6.jpg',
        medium: 'assets/images/animals/6.jpg',
        big: 'assets/images/animals/6.jpg',
        description:'One stroke painting by me.'
      },
      {
        small: 'assets/images/animals/7.jpg',
        medium: 'assets/images/animals/7.jpg',
        big: 'assets/images/animals/7.jpg',
        description:'Net flowers and quilling art on vase by me.'
      },
      {
        small: 'assets/images/animals/8.jpg',
        medium: 'assets/images/animals/8.jpg',
        big: 'assets/images/animals/8.jpg',
        description:' Quilling art on vase by me.'
      },
      {
        small: 'assets/images/animals/9.jpg',
        medium: 'assets/images/animals/9.jpg',
        big: 'assets/images/animals/9.jpg',
        description:'Quilling art on envelope by me.'
      },
      {
        small: 'assets/images/animals/10.jpg',
        medium: 'assets/images/animals/10.jpg',
        big: 'assets/images/animals/10.jpg',
        description:'Quilling art on phot frame by me.'
      },
      {
        small: 'assets/images/animals/11.jpg',
        medium: 'assets/images/animals/11.jpg',
        big: 'assets/images/animals/11.jpg',
        description:'Canvas Oil painting by me.'
      },
      {
        small: 'assets/images/animals/12.jpg',
        medium: 'assets/images/animals/12.jpg',
        big: 'assets/images/animals/12.jpg',
        description:'Canvas Oil painting by me.'
      },
      {
        small: 'assets/images/animals/13.jpg',
        medium: 'assets/images/animals/13.jpg',
        big: 'assets/images/animals/13.jpg',
        description:'Pencil sketching by me.'
      }

    ];


    this.galleryImagesNature = [
      {
        small: 'assets/images/nature/1.jpg',
        medium: 'assets/images/nature/1.jpg',
        big: 'assets/images/nature/1.jpg',
        description:'In Greece with my family'
      },
      {
        small: 'assets/images/nature/2.jpg',
        medium: 'assets/images/nature/2.jpg',
        big: 'assets/images/nature/2.jpg',
        description:'In Paris with my family'
      },
      {
        small: 'assets/images/nature/3.jpg',
        medium: 'assets/images/nature/3.jpg',
        big: 'assets/images/nature/3.jpg',
        description:'In Switzerland with my family'
      },
      {
        small: 'assets/images/nature/4.jpg',
        medium: 'assets/images/nature/4.jpg',
        big: 'assets/images/nature/4.jpg',
        description:'In Italy with my family'
      },
      {
        small: 'assets/images/nature/5.jpg',
        medium: 'assets/images/nature/5.jpg',
        big: 'assets/images/nature/5.jpg',
        description:'In Pisa with my family'
      },
      {
        small: 'assets/images/nature/6.jpg',
        medium: 'assets/images/nature/6.jpg',
        big: 'assets/images/nature/6.jpg',
        description:'In Rome with my family'
      },
      {
        small: 'assets/images/nature/7.jpg',
        medium: 'assets/images/nature/7.jpg',
        big: 'assets/images/nature/7.jpg',
        description:'In Venice with my family'
      },
      {
        small: 'assets/images/nature/8.jpg',
        medium: 'assets/images/nature/8.jpg',
        big: 'assets/images/nature/8.jpg',
        description:'In Vatican City with my family'
      },
      {
        small: 'assets/images/nature/9.jpg',
        medium: 'assets/images/nature/9.jpg',
        big: 'assets/images/nature/9.jpg',
        description:'In Istanbul, Turkey with my family'
      }
    ];

  }

}
