import { Component, OnInit } from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
  
})
export class ResumeComponent implements OnInit {

  

  pdfSrc = 'assets/resume/resume.pdf';
  faDownload = faDownload;
 
  
  constructor() { }

  ngOnInit(): void {
  }
   
  openLink() {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = this.pdfSrc;
    a.download = "Mukta Jaiswal.pdf";
    a.click();
    // Remove the link when done
    document.body.removeChild(a);
  }

}
