
<footer style=" background-image:url('assets/images/foot/bg.jpg');  background-position: center; background-size: 100%;" >
    <div class="container" >
        <div class="row " >
            <div class="col-md-4 text-center text-md-left ">
                
                <div class="py-0">
                    <h3 class="my-4 text-white"><span class="mx-2 font-italic" style="color: #cc7c80">Mukta Jaiswal</span></h3>
 
                    <div class="py-2 my-4 text-white text-center text-md-left">
                        <div>
                            <p class="text-white"> <i class="fa fa-map-marker mx-2 "></i>
                                Davis, California</p>
                        </div>
    
                        <div> 
                            <!-- <p><i class="fa fa-phone  mx-2 "></i> +91 22-27782183</p> -->
                        </div>
                        <div>
                            <p><i class="fa fa-envelope  mx-2"></i><a href="mailto:mukta@jaiswal.in">mukta@jaiswal.in</a></p>
                        </div>  
                    </div> 
                    <div class="">
                       
                    
                        <div class="pt-6">
                            <div class="social-media" > </div>
                        
                        <a href="https://www.facebook.com/mukta.jaiswal.13" target="_blank"><i class="fa fa-facebook fa-2x text-primary mx-2"></i></a>
                    <a href="https://www.linkedin.com/in/mukta-jaiswal-99270a221" target="_blank"><i class="fa fa-linkedin fa-2x text-info mx-2"></i></a>
                    <a href="https://www.youtube.com/channel/UC2bWWzZ4pRbKZB_HjTKhKSQ" target="_blank"><i class="fa fa-youtube fa-2x text-danger mx-2"></i></a>
                </div>
                    </div>
                    
                    
                </div>
            </div>
            
            <div class="col-md-3 text-white text-center text-md-left py-2 my-2 ">
                <h5 class="my-4 text-white font-weight-bold">Quick Links</h5>
                <p class="footer-links ">
                    <a class="text-white"  routerLink="/home" >  About </a>
                    <br>
                    <a class="text-white"  routerLink="/resume" >  Resume </a>
                    <br>
                    <a class="text-white"  routerLink="/gallery" >  Gallery </a>
                    <br>
                    <a class="text-white"  routerLink="/achievements" >  Achievements </a>
                    <br>
                    <a class="text-white"  routerLink="/articles" >  Blogs </a>
                    <br>
                    <a class="text-white"  routerLink="/contact" >  Contact </a>
                    
                    
                </p>



                
            </div>
            
            <div class="col-md-5 text-white my-4 text-center text-md-left ">
                <span class=" font-weight-bold ">About Me</span>
                <p class=" my-2 " style="color: #cc7c80;  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif ; font-style: italic;" >  I am Mukta Jaiswal, a computer enthusiast with a profound knowledge of several programming languages. I will complete my graduation from UC Davis in 2023.</p>
                <br>
                <div class="py-2">
                    <p class="text-light py-4 mb-4" style="font-size: 14px;">&copy;2021 All rights reserved. Designed & Developed by Mukta Jaiswal. </p>
                </div>
            </div>
        </div>  
    </div>
    
 </footer>
    