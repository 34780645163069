<section id="blog">
    <div class="container-allPage" style="zoom: 85%;">
        <div id="grid-blog" class="grid-container">
            
            <div class="span-wrapper">
                <span class="title-shadow">Blogs</span>
            </div>
            <div class="headerSection">
                <h2>Blogs</h2>
            </div>
            
           
            <div class="col col-0 blog" style="display: block;">
                <div class="popUp-wrapper-img"><img src=" assets/images/webdesign.jpg" alt="Web design" loading="lazy">
                </div>
                <div class="wrap-blog">
                    <div class="blog-title">
                        <h3>Common Mistakes While Creating a Website</h3>
                    </div>
                    <div class="marginTopText">
                        <div class="date"><i class="icofont-ui-calendar"></i>
                            <p>13 October 2020</p>
                        </div>
                        <p>The website has less than 15 seconds to grab visitor's attention. Failing to do so will not only result in higher bounce rate but in most probability the visitor may never return. The idea is to make an instant connection, and it can only happen when you make a clearly defined introduction. This rapid engagement can be achieved via header or carousel which offer a peek into the company products. Cramming this section with unwanted information is one of the common website mistakes. As mentioned, carousel need to be efficient and succinct. It is pertinent that you provide information about your brand, the people behind it in these static or dynamic reflections. Avoiding or missing out on these basic parameters will result in failed digital goals.</p>
                        
                    </div>
                    
                    
                    <p><a  class="text-bold" data-toggle="modal" data-target="#myModal">READ MORE</a></p>
                    <!-- Modal -->
<div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
  
      <!-- Modal content-->
       <div class="modal-content"> 
        <div class="modal-header">
          
         
          <h2 class="modal-title">Common Mistakes While Creating a Websiteb - by Mukta Jaiswal</h2>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
             <!-- <img src="assets/images/webdesign.jpg" alt="Web design">  -->
          <p>
            
            The website has less than 15 seconds to grab visitor's attention. Failing to do so will not only result in higher bounce rate but in most probability the visitor may never return. The idea is to make an instant connection, and it can only happen when you make a clearly defined introduction. This rapid engagement can be achieved via header or carousel which offer a peek into the company products. Cramming this section with unwanted information is one of the common website mistakes. As mentioned, carousel need to be efficient and succinct. It is pertinent that you provide information about your brand, the people behind it in these static or dynamic reflections. Avoiding or missing out on these basic parameters will result in failed digital goals.</p>
          <p class=" restP">Another common mistake many website designers and developers commit is hiding the navigation within unwanted web elements or simply making it too difficult to access. This typically happens in a cluttered environment. A disorderedly and littered website is the result of amateurish or ignorant use of fancy typefaces, colors, banners and info graphics.
            One should not fail to provide a distinct, well defined and bold CTA button. Many website developers focus on aesthetics and ease of use but fail to convert this impressive setup into a sales goldmine. Make sure the "Contact Us Today", "Buy Now" and "Subscribe" buttons stand out sharp and clear. 
            
            </p>
          <p class=" restP">Website designers and developers generally lack in terms of content depth. Its absence can not only result in poor SEO but turn even a well-planned website into a useless showcase, an online window to fancy images and meaningless web elements. Content de-clutters a web page, provides essential product information and also keeps the visitor hooked. 

        </p>
          <p class=" restP">Developers and owners need to address the needs of ever growing smart phone users. This is yet another mistake commonly found in many websites. Lack of support for mobile and other hand held devices is simply unforgivable. Your website needs to be responsive on every device and screen resolution, which is very important for universal access. </p>

          <p> 
            Another minor mistake synonymous with many websites is the absence of social links. By not providing references of your social links, your organization reflects poorly on the social scale. It makes visitors wonder if your services or products are in sync with the times or are obsolete in nature. Moreover, social engagement leads to improved lead generation and rapid brand building. Failing to capitalize can only result in failed digital marketing plans.
          </p>

          <p>
            Finally, slow loading website is a sin and many web creators fail to look at this essential criterion.
          </p>


        </div>
        

      <div class="modal-footer"> 
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div> 


      </div>


  
    </div> 
  </div>

                 </div>
            </div>

            <div class="col col-0 blog" style="display: block;">
                <div class="popUp-wrapper-img"><img src=" assets/images/proctorio.jpg" alt="proctorio" loading="lazy">
                </div>
                <div class="wrap-blog">
                    <div class="blog-title">
                        <h3>Using Proctoring extensions for conducting online exams</h3>
                    </div>
                    <div class="marginTopText">
                        <div class="date"><i class="icofont-ui-calendar"></i>
                            <p>11 December 2020</p>
                        </div>
                        <p>The year ‘2020’ has brought significant changes in everyone’s lives. Everyone has been working remotely this year, and therefore all the education platforms have also been running online since then. Online education system means online examinations, and online examinations means that it has to be closely monitored remotely, which can be tough. Therefore, many education institutions use online proctoring extensions which can be associated with any online education portal, for example canvas, and thus it monitors the screen of the computer, and doubtful background details while the student is giving the exam. It basically secures the idea of conducting the online exam in the similar manner like it would have been conducted in-person. </p>
                        
                    </div>
                    
                    
                    <p><a  class="text-bold" data-toggle="modal" data-target="#myModal1">READ MORE</a></p>
                    <!-- Modal -->
<div id="myModal1" class="modal fade" role="dialog">
    <div class="modal-dialog">
  
      <!-- Modal content-->
       <div class="modal-content"> 
        <div class="modal-header">
          
         
          <h2 class="modal-title">Using Proctoring extensions for conducting online exams - by Mukta Jaiswal</h2>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          
             
          <p>
            
            The year ‘2020’ has brought significant changes in everyone’s lives. Everyone has been working remotely this year, and therefore all the education platforms have also been running online since then. Online education system means online examinations, and online examinations means that it has to be closely monitored remotely, which can be tough. Therefore, many education institutions use online proctoring extensions which can be associated with any online education portal, for example canvas, and thus it monitors the screen of the computer, and doubtful background details while the student is giving the exam. It basically secures the idea of conducting the online exam in the similar manner like it would have been conducted in-person. </p>
          <p class=" restP">There can be several pros and cons of using proctoring extensions for online exams. My college has been using proctoring extension namely ‘Proctorio’ with canvas. The pros of using proctoring extensions are that it can prevent the student from cheating during the exam. It usually flags any unusual activities done by the student during the exam, and sends the report to the professor after the exam is over. It is completely safe as it only records the screen on which the exam is running and later it can be deleted after the grading is done. It asks for an identification proof before the exam starts, just to be sure it’s the right person giving the exam. It also does not let you copy anything to the clipboard so that no one can copy-paste the answers from their desktop or other websites.
            
            </p>
          <p class=" restP">The cons of proctoring extensions are that sometimes extensions like ‘Proctorio’ ask you to scan your room with the camera before your exam starts just to make sure you do not have any notes lying around you, so sometimes the students might not feel comfortable in doing that due to privacy concerns. Once you exit the exam, the ‘Proctorio’ blocks the exam, so you cannot enter the exam again. This is a disadvantage because I’ve personally encountered a situation where a pop-up by Adobe flash player had sent me a reminder to update it and it automatically opened up its website when I was in the middle of typing my answer. Due to this, ‘Proctorio’ blocked my exam and I couldn’t re-enter to complete my final paper in spite of knowing all the answers and having sufficient amount of time left to complete it. Luckily, my professor was very responsive on e-mail during the exam and we sorted this issue, but what would I do if she was not convinced that it happened accidentally? This can be very stressful for the students when such things happen accidentally, and there is no way to resolve it until the professor responds and agrees to resolve it. 



        </p>
          <p class=" restP">One has to be very careful while using these extensions during the exam, and should always make sure to block all notifications on their desktop before starting the paper. Proctoring extensions are very useful, but it can be a little stressful for the students sometimes, so students should read all the instructions carefully before starting an exam on any proctoring extension.

        </p>

          


        </div>
        

      <div class="modal-footer"> 
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div> 


      </div>


  
    </div> 
  </div>

                 </div>
            </div>


            <div class="col col-0 blog" style="display: block;">
              <div class="popUp-wrapper-img"><img src=" assets/images/google.jpg" alt="google" loading="lazy">
              </div>
              <div class="wrap-blog">
                  <div class="blog-title">
                      <h3>What will happen if Google stops working for a day?</h3>
                  </div>
                  <div class="marginTopText">
                      <div class="date"><i class="icofont-ui-calendar"></i>
                          <p>18 December 2020</p>
                      </div>
                      <p>Recently, on 14th December, 2020, Google had experienced a big global outage at around 3:30am PST for about 40-45 mins. On this day we all realized our huge amount of dependency on Google products in our daily life, especially during the pandemic when everything’s operating remotely. From Google search engine to google maps, we are dependent on google for almost everything online. The 45 mins outage had created a bizarre on the internet in everyone’s life, and eventually it makes me ponder on what will happen if Google stops working for a day?

                      </p>
                      
                  </div>
                  
                  
                  <p><a  class="text-bold" data-toggle="modal" data-target="#myModal2">READ MORE</a></p>
                  <!-- Modal -->
<div id="myModal2" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
     <div class="modal-content"> 
      <div class="modal-header">
        
       
        <h2 class="modal-title">What will happen if Google stops working for a day? - by Mukta Jaiswal</h2>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        
           
        <p>
          
          Recently, on 14th December, 2020, Google had experienced a big global outage at around 3:30am PST for about 40-45 mins. On this day we all realized our huge amount of dependency on Google products in our daily life, especially during the pandemic when everything’s operating remotely. From Google search engine to google maps, we are dependent on google for almost everything online. The 45 mins outage had created a bizarre on the internet in everyone’s life, and eventually it makes me ponder on what will happen if Google stops working for a day?

 </p>
        <p class=" restP">All the Google products will be non-functional when Google stops working for that day. This outage will incur huge losses on companies which are highly dependent on google products for their operations. There’ll be a huge drop in the share market globally. It’ll affect the whole world economically. Google Meet will not work, and therefore many companies who are dependent on google meet app for online conferences will not be able to meet on that day. 
          
          </p>
        <p class=" restP">Google Maps will also not function, and thus cause problems to navigate the most efficient routes to our destination. There might be people who might miss their interviews or important conferences since the most accurate traffic updates will not be available. It’ll also cause issues to the ambulances and emergency vehicles which are dependent on Google Map to search a shortest route to reach their destination as soon as possible.





      </p>
        <p class=" restP">During the pandemic when all the education is being taught online, and if Google goes down for a day during this time then the education institutions which were dependent on the google classroom feature will not be able to operate on that day. 

      </p>
      <p class=" restP">Without Google play store, there’ll be almost no android user downloading any app from the play store, therefore causing losses to the app owners who could have probably profited if the play store would have been working.

      </p>
      <p class=" restP">Google mail and the cloud-based services of Google Drive are used by almost everyone in this world. Majority of the people rely on Google mail for e-mail services, and during the outage, vast number of people were affected due to this. In this online world, errors on the Gmail page would lead to the stoppage of the communication between everyone on the business platform, and thus causing huge financial loss. Google Drive is used by many of us to store important files, folders, documents and photographs which might not be accessible if the google drive stops responding until it’s already stored somewhere offline.

      </p>
      <p class=" restP">So, how should one be prepared to face such situation in the future? One should try to have multiple back-ups of their important documents, so that incase of any outage of any online platform, one can rely on the other sources when needed urgently. We should not be heavily dependent on just one source. For example, for those who only use Gmail, on 14th Dec, 2020,when the Gmail server was down due to the outage, it’s a good practice to pre-download all the mails on Microsoft Outlook or on Apple Mail so that you can easily access any information from these mails for your reference, if needed. 

      </p>
      <p class=" restP">Finally, to conclude, it’s important and safe to always have different sources open in case one of the online options shuts down suddenly. We have become very dependent on Google products and the internet for numerous day-to-day activities, just so that we cannot imagine ourselves without it. Just imagine, you forgot to pay your electricity bill and Google Pay shuts down due to technical glitches, then what will you do if you do not know how to use any other online payment option or you have not pre-registered yourself through your Gmail id on any other online payment app? Therefore, life without google might be difficult, but survivable if you keep alternate options open for important things.



      </p>

        


      </div>
      

    <div class="modal-footer"> 
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div> 


    </div>



  </div> 
</div>

               </div>
          </div>

            


     </div>
    </div>

    <!-- Trigger the modal with a button -->



</section>

